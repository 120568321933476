/*jshint esversion: 6 */
const makeFocusOnTargetAfterGoOnLink = function (link) {
    const href = jQuery(link).attr('href');
    if (href && href.indexOf('#') === 0) {
        jQuery(link).click(function(){
            jQuery(href).focus();
        });
    }
};

jQuery(function($){
    $('body').on('click', 'a[data-anchor="true"]', function() {
        makeFocusOnTargetAfterGoOnLink($(this));
    });

    $('body').on('click', 'a[data-anchor="form"]', function() {
        const href = $(this).attr('href');
        if (href && href.indexOf('#') === 0) {
            var $this = $(href);
            var $form = $this.find('form');
            if ($form.length > 0) {
                $form = $($form[0]);
            }
            var $formParent = $this;
            while ($form.length < 1) {
                $formParent = $formParent.parent();
                $form = $formParent.find('form');
                if ($form.length > 0) {
                    $form = $($form[0]);
                }
            }
            if ($form.find('input:visible') > 0) {
                $($form.find('input:visible')[0]).focus();
            }
        }
    });
});
