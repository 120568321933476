let cards = document.querySelectorAll('[data-card]');

Array.prototype.forEach.call(cards, card => {
  let link = card.getAttribute('data-card-url');

  card.addEventListener('keydown', function(e) {
    if (e.target !== this) {
       return;
    }

    let KEY_ENTER = 13;
    let KEY_SPACE = 32;

    if (e.keyCode === KEY_ENTER || e.keyCode === KEY_SPACE) {
      e.preventDefault();
      window.location.href = link;
    }
  });
});
