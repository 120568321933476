/*jshint esversion: 6 */

/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*
*/

class BlvdAccessibilityMenuButton {

    constructor(domNode) {
        this.domNode = domNode;
        this.popupMenu = false;

        this.hasFocus = false;
        this.hasHover = false;

        this.keyCode = Object.freeze({
            'TAB': 9,
            'RETURN': 13,
            'ESC': 27,
            'SPACE': 32,
            'PAGEUP': 33,
            'PAGEDOWN': 34,
            'END': 35,
            'HOME': 36,
            'LEFT': 37,
            'UP': 38,
            'RIGHT': 39,
            'DOWN': 40
        });
    }

}

BlvdAccessibilityMenuButton.prototype.init = function () {

    this.domNode.setAttribute('aria-haspopup', 'true');
    this.domNode.addEventListener('keydown',    this.handleKeydown.bind(this));
    this.domNode.addEventListener('click',      this.handleClick.bind(this));
    this.domNode.addEventListener('focus',      this.handleFocus.bind(this));
    this.domNode.addEventListener('blur',       this.handleBlur.bind(this));
    this.domNode.addEventListener('mouseover',  this.handleMouseover.bind(this));
    this.domNode.addEventListener('mouseout',   this.handleMouseout.bind(this));

    // initialize pop up menus

    const popupMenu = document.getElementById(this.domNode.getAttribute('aria-controls'));

    if (popupMenu) {
        this.popupMenu = new BlvdAccessibilityPopupMenuLinks(popupMenu, this);
        this.popupMenu.init();
    }

};

BlvdAccessibilityMenuButton.prototype.handleKeydown = function (event) {
    let flag = false;

    switch (event.keyCode) {
        case this.keyCode.SPACE:
        case this.keyCode.RETURN:
        case this.keyCode.DOWN:
            if (this.popupMenu) {
                this.popupMenu.open();
                this.popupMenu.setFocusToFirstItem();
            }
            flag = true;
            break;

        case this.keyCode.UP:
            if (this.popupMenu) {
                this.popupMenu.open();
                this.popupMenu.setFocusToLastItem();
                flag = true;
            }
            break;

        default:
            break;
    }

    if (flag) {
        event.stopPropagation();
        event.preventDefault();
    }
};

BlvdAccessibilityMenuButton.prototype.handleClick = function (event) {
    if (this.domNode.getAttribute('aria-expanded') == 'true') {
        this.popupMenu.close(true);
    }
    else {
        this.popupMenu.open();
        this.popupMenu.setFocusToFirstItem();
    }
};

BlvdAccessibilityMenuButton.prototype.handleFocus = function (event) {
    this.popupMenu.hasFocus = true;
};

BlvdAccessibilityMenuButton.prototype.handleBlur = function (event) {
    this.popupMenu.hasFocus = false;
};

BlvdAccessibilityMenuButton.prototype.handleMouseover = function (event) {
    this.hasHover = true;
    this.popupMenu.open();
};

BlvdAccessibilityMenuButton.prototype.handleMouseout = function (event) {
    this.hasHover = false;
    setTimeout(this.popupMenu.close.bind(this.popupMenu, false), 300);
};

class BlvdAccessibilityPopupMenuLinks {

    constructor(domNode, controllerObj) {
        const  msgPrefix = 'BlvdAccessibilityPopupMenuLinks constructor argument domNode ';

        // Check whether domNode is a DOM element
        if (!(domNode instanceof Element)) {
            throw new TypeError(msgPrefix + 'is not a DOM Element.');
        }

        // Check whether domNode has child elements
        if (domNode.childElementCount === 0) {
            throw new Error(msgPrefix + 'has no element children.');
        }

        // Check whether domNode descendant elements have A elements
        let childElement = domNode.firstElementChild;
        while (childElement) {
            const menuitem = childElement.firstElementChild;
            if (menuitem && menuitem.tagName !== 'A') {
                throw new Error(msgPrefix + 'has descendant elements that are not A elements.');
            }
            childElement = childElement.nextElementSibling;
        }

        this.domNode = domNode;
        this.controller = controllerObj;

        this.menuitems  = [];      // see BlvdAccessibilityPopupMenuLinks init method
        this.firstChars = [];      // see BlvdAccessibilityPopupMenuLinks init method

        this.firstItem  = null;    // see BlvdAccessibilityPopupMenuLinks init method
        this.lastItem   = null;    // see BlvdAccessibilityPopupMenuLinks init method

        this.hasFocus   = false;   // see BlvdAccessibilityMenuItemLinks handleFocus, handleBlur
        this.hasHover   = false;   // see BlvdAccessibilityPopupMenuLinks handleMouseover, handleMouseout
    }


}

BlvdAccessibilityPopupMenuLinks.prototype.init = function () {
    let childElement, menuElement, menuItem, textContent, numItems, label;

    // Configure the domNode itself
    this.domNode.tabIndex = -1;

    this.domNode.setAttribute('role', 'menu');

    if (!this.domNode.getAttribute('aria-labelledby') && !this.domNode.getAttribute('aria-label') && !this.domNode.getAttribute('title')) {
        label = this.controller.domNode.innerHTML;
        this.domNode.setAttribute('aria-label', label);
    }

    this.domNode.addEventListener('mouseover', this.handleMouseover.bind(this));
    this.domNode.addEventListener('mouseout',  this.handleMouseout.bind(this));

    // Traverse the element children of domNode: configure each with
    // menuitem role behavior and store reference in menuitems array.
    childElement = this.domNode.firstElementChild;

    while (childElement) {
        menuElement = childElement.firstElementChild;

        if (menuElement && menuElement.tagName === 'A') {
            menuItem = new BlvdAccessibilityMenuItemLinks(menuElement, this);
            menuItem.init();
            this.menuitems.push(menuItem);
            textContent = menuElement.textContent.trim();
            this.firstChars.push(textContent.substring(0, 1).toLowerCase());
        }
        childElement = childElement.nextElementSibling;
    }

    // Use populated menuitems array to initialize firstItem and lastItem.
    numItems = this.menuitems.length;
    if (numItems > 0) {
        this.firstItem = this.menuitems[0];
        this.lastItem  = this.menuitems[numItems - 1];
    }
};

/* EVENT HANDLERS */

BlvdAccessibilityPopupMenuLinks.prototype.handleMouseover = function (event) {
    this.hasHover = true;
};

BlvdAccessibilityPopupMenuLinks.prototype.handleMouseout = function (event) {
    this.hasHover = false;
    setTimeout(this.close.bind(this, false), 300);
};

/* FOCUS MANAGEMENT METHODS */

BlvdAccessibilityPopupMenuLinks.prototype.setFocusToController = function (command) {
    if (typeof command !== 'string') {
        command = '';
    }

    if (command === 'previous') {
        this.controller.menubar.setFocusToPreviousItem(this.controller);
    }
    else {
        if (command === 'next') {
            this.controller.menubar.setFocusToNextItem(this.controller);
        }
        else {
            this.controller.domNode.focus();
        }
    }
};

BlvdAccessibilityPopupMenuLinks.prototype.setFocusToFirstItem = function () {
    this.firstItem.domNode.focus();
};

BlvdAccessibilityPopupMenuLinks.prototype.setFocusToLastItem = function () {
    this.lastItem.domNode.focus();
};

BlvdAccessibilityPopupMenuLinks.prototype.setFocusToPreviousItem = function (currentItem) {
    let index;

    if (currentItem === this.firstItem) {
        this.lastItem.domNode.focus();
    }
    else {
        index = this.menuitems.indexOf(currentItem);
        this.menuitems[index - 1].domNode.focus();
    }
};

BlvdAccessibilityPopupMenuLinks.prototype.setFocusToNextItem = function (currentItem) {
    let index;

    if (currentItem === this.lastItem) {
        this.firstItem.domNode.focus();
    }
    else {
        index = this.menuitems.indexOf(currentItem);
        this.menuitems[index + 1].domNode.focus();
    }
};

BlvdAccessibilityPopupMenuLinks.prototype.setFocusByFirstCharacter = function (currentItem, char) {
    let start, index;
    char = char.toLowerCase();

    // Get start index for search based on position of currentItem
    start = this.menuitems.indexOf(currentItem) + 1;
    if (start === this.menuitems.length) {
        start = 0;
    }

    // Check remaining slots in the menu
    index = this.getIndexFirstChars(start, char);

    // If not found in remaining slots, check from beginning
    if (index === -1) {
        index = this.getIndexFirstChars(0, char);
    }

    // If match was found...
    if (index > -1) {
        this.menuitems[index].domNode.focus();
    }
};

BlvdAccessibilityPopupMenuLinks.prototype.getIndexFirstChars = function (startIndex, char) {
    for (let i = startIndex; i < this.firstChars.length; i++) {
        if (char === this.firstChars[i]) {
            return i;
        }
    }
    return -1;
};

/* MENU DISPLAY METHODS */

BlvdAccessibilityPopupMenuLinks.prototype.open = function () {
    // get position and bounding rectangle of controller object's DOM node
    const rect = this.controller.domNode.getBoundingClientRect();

    // set CSS properties
    this.domNode.style.display = 'block';
    this.domNode.style.position = 'absolute';
    this.domNode.style.top  = rect.height + 'px';
    this.domNode.style.left = '0px';

    // set aria-expanded attribute
    this.controller.domNode.setAttribute('aria-expanded', 'true');
};

BlvdAccessibilityPopupMenuLinks.prototype.close = function (force) {

    if (force || (!this.hasFocus && !this.hasHover && !this.controller.hasHover)) {
        this.domNode.style.display = 'none';
        this.controller.domNode.removeAttribute('aria-expanded');
    }
};


class BlvdAccessibilityMenuItemLinks {

    constructor(domNode, menuObj) {
        this.domNode = domNode;
        this.menu = menuObj;

        this.keyCode = Object.freeze({
            'TAB': 9,
            'RETURN': 13,
            'ESC': 27,
            'SPACE': 32,
            'PAGEUP': 33,
            'PAGEDOWN': 34,
            'END': 35,
            'HOME': 36,
            'LEFT': 37,
            'UP': 38,
            'RIGHT': 39,
            'DOWN': 40
        });
    }
}

BlvdAccessibilityMenuItemLinks.prototype.init = function () {
    this.domNode.tabIndex = -1;

    if (!this.domNode.getAttribute('role')) {
        this.domNode.setAttribute('role', 'menuitem');
    }

    this.domNode.addEventListener('keydown',    this.handleKeydown.bind(this));
    this.domNode.addEventListener('click',      this.handleClick.bind(this));
    this.domNode.addEventListener('focus',      this.handleFocus.bind(this));
    this.domNode.addEventListener('blur',       this.handleBlur.bind(this));
    this.domNode.addEventListener('mouseover',  this.handleMouseover.bind(this));
    this.domNode.addEventListener('mouseout',   this.handleMouseout.bind(this));

};

/* EVENT HANDLERS */

BlvdAccessibilityMenuItemLinks.prototype.handleKeydown = function (event) {
    let flag = false,
    char = event.key;

    function isPrintableCharacter (str) {
        return str.length === 1 && str.match(/\S/);
    }

    if (event.ctrlKey || event.altKey  || event.metaKey || (event.keyCode === this.keyCode.SPACE) || (event.keyCode === this.keyCode.RETURN)) {
        return;
    }

    if (event.shiftKey) {
        if (isPrintableCharacter(char)) {
            this.menu.setFocusByFirstCharacter(this, char);
            flag = true;
        }

        if (event.keyCode === this.keyCode.TAB) {
            this.menu.setFocusToController();
            this.menu.close(true);
        }
    }
    else {
        switch (event.keyCode) {

            case this.keyCode.ESC:
                this.menu.setFocusToController();
                this.menu.close(true);
                flag = true;
                break;

            case this.keyCode.UP:
                this.menu.setFocusToPreviousItem(this);
                flag = true;
                break;

            case this.keyCode.DOWN:
                this.menu.setFocusToNextItem(this);
                flag = true;
                break;

            case this.keyCode.HOME:
            case this.keyCode.PAGEUP:
                this.menu.setFocusToFirstItem();
                flag = true;
                break;

            case this.keyCode.END:
            case this.keyCode.PAGEDOWN:
                this.menu.setFocusToLastItem();
                flag = true;
                break;

            case this.keyCode.TAB:
                this.menu.setFocusToController();
                this.menu.close(true);
                break;

            default:
                if (isPrintableCharacter(char)) {
                    this.menu.setFocusByFirstCharacter(this, char);
                }
                break;
        }
    }

    if (flag) {
        event.stopPropagation();
        event.preventDefault();
    }
};

BlvdAccessibilityMenuItemLinks.prototype.handleClick = function (event) {
    this.menu.setFocusToController();
    this.menu.close(true);
};

BlvdAccessibilityMenuItemLinks.prototype.handleFocus = function (event) {
    this.menu.hasFocus = true;
};

BlvdAccessibilityMenuItemLinks.prototype.handleBlur = function (event) {
    this.menu.hasFocus = false;
    setTimeout(this.menu.close.bind(this.menu, false), 300);
};

BlvdAccessibilityMenuItemLinks.prototype.handleMouseover = function (event) {
    this.menu.hasHover = true;
    this.menu.open();

};

BlvdAccessibilityMenuItemLinks.prototype.handleMouseout = function (event) {
    this.menu.hasHover = false;
    setTimeout(this.menu.close.bind(this.menu, false), 300);
};
