/*jshint esversion: 6 */

Array.from(document.getElementsByClassName("share-buttons-menu-button")).forEach(
    function(element, index, array) {
        const shareButtonsMenuButton = new BlvdAccessibilityMenuButton(element);
        shareButtonsMenuButton.init();
    }
);




